import Keycloak from 'keycloak-js';

let keycloak = null;

export const initKeycloak = () => {
  return new Promise((resolve, reject) => {
    keycloak = new Keycloak({
      url: 'https://sso.loxone.com/',
      realm: 'loxone',
      clientId: 'portal',
    });

    const storedToken = localStorage.getItem('keycloak-token');
    const storedRefreshToken = localStorage.getItem('keycloak-refresh-token');

    keycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: true,
      token: storedToken,
      refreshToken: storedRefreshToken,
    }).then(async (authenticated) => {
      if (authenticated) {
        localStorage.setItem('keycloak-token', keycloak.token);
        localStorage.setItem('keycloak-refresh-token', keycloak.refreshToken);
        resolve(keycloak.token);
      } else {
        keycloak.login();
        resolve(null);
      }
    }).catch((error) => {
      console.error('Keycloak initialization failed:', error);
      reject(error);
    });
  });
};

export const logoutKeycloak = () => {
    /* try {
      alert('logout3');
      await keycloak.updateToken(10); // Ensure token is valid before logout
    } catch (err) {
      console.warn("Token update failed, logging out anyway", err);
    } */
    //const idToken = keycloak.idToken;
    localStorage.clear(); // Remove all tokens and session data
    //keycloak.logout();
    // Redirect to Keycloak logout page directly
    window.location.href = `https://sso.loxone.com/realms/loxone/protocol/openid-connect/logout?client_id=portal&post_logout_redirect_uri=https://portal.loxone.com/`;
  };
